$(document).ready(function () {
  $(".info__slider").slick({
    slidesToShow: 3,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true,
    dots: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: true,
        },
      },
    ],
  });
  $(".reviews__slider").slick({
    slidesToShow: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true,
    dots: false,
    responsive: [
      {
        breakpoint: 811,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          arrows: true,
        },
      },
    ],
  });
  $(".works__slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    swipe: false,
    draggable: false,
    asNavFor: ".works__nav",
  });
  $(".works__nav").slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: ".works__slider",
    dots: false,
    centerMode: true,
    focusOnSelect: true,
    swipe: false,
    draggable: false,
    infinite: false,
  });
  $(".gallery__slider").slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".gallery__nav",
    // adaptiveHeight: true,
  });
  $(".gallery__nav").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".gallery__slider",
    dots: false,
    arrows: false,
    centerMode: true,
    focusOnSelect: true,
  });
});

// Anchor Scrolling
function initAnchorScrolling() {
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, "") ==
          this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $("html, body").animate(
            {
              scrollTop: target.offset().top,
            },
            1000,
            function () {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(":focus")) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr("tabindex", "-1"); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });
}

//Smooth Animation on Scroll
const animItems = document.querySelectorAll("._anim-items");

if (animItems.length > 0) {
  window.addEventListener("scroll", animOnScroll);
  function animOnScroll(params) {
    for (let index = 0; index < animItems.length; index++) {
      const animItem = animItems[index];
      const animItemHeight = animItem.offsetHeight;
      const animItemOffset = offset(animItem).top;
      const animStart = 4;

      let animItemPoint = window.innerHeight - animItemHeight / animStart;
      if (animItemHeight > window.innerHeight) {
        animItemPoint = window.innerHeight - window.innerHeight / animStart;
      }

      if (
        pageYOffset > animItemOffset - animItemPoint &&
        pageYOffset < animItemOffset + animItemHeight
      ) {
        animItem.classList.add("_active");
      } else {
        if (!animItem.classList.contains("_anim-hide")) {
          animItem.classList.remove("_active");
        }
      }
    }
  }
  function offset(el) {
    const rect = el.getBoundingClientRect(),
      scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
      scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + screenLeft };
  }
  animOnScroll();
}

// Check if element entered viewport
function isInViewport(el) {
  var rect = el.getBoundingClientRect();

  return rect.top < window.innerHeight && rect.bottom >= 0;
}

("use strict");
document.addEventListener("click", documentActions);
function documentActions(e) {
  const targetElement = e.target;
  if (targetElement.closest(".burger__button")) {
    document.documentElement.classList.toggle("burger__open");
  }
  if (targetElement.closest("[data-goto]")) {
    document.documentElement.classList.contains("burger__open")
      ? document.documentElement.classList.remove("burger__open")
      : null;
    const goTo = targetElement.closest("[data-goto]").dataset.goto;
    const goToElement = document.querySelector(goTo);
    const headerHeight = document.querySelector(".header").offsetHeight;
    if (goToElement) {
      window.scrollTo({
        top: goToElement.offsetTop - (headerHeight + 15),
        behavior: "smooth",
      });
    }
    e.preventDefault();
  }
}

//POPUP
$("a.popup-link").click(function (e) {
  e.preventDefault();
  var destinationPopup = $(this).attr("href");
  $(destinationPopup).addClass("open");
  $(destinationPopup + " .popup__close").click(function (e) {
    e.preventDefault();
    $(destinationPopup).removeClass("open");
  });
  $(destinationPopup + " .popup-overlay").click(function () {
    $(destinationPopup).removeClass("open");
  });
});

$(function () {
  $("form").on("submit", function (e) {
    e.preventDefault();

    const $form = $(this);

    const $popupConfirm = $("#modal1");

    $.ajax({
      url: $form.attr("action"),
      type: $form.attr("method"),
      data: new FormData(this),
      processData: false,
      contentType: false,
    }).done(function () {
      $form.trigger("reset");

      $popupConfirm.addClass("open");

      const $closeBtn = $popupConfirm.find(".popup__close");
      const $overlay = $popupConfirm.find(".popup-overlay");

      $closeBtn.click(function (e) {
        e.preventDefault();
        $popupConfirm.removeClass("open");
        $closeBtn.off("click");
      });
      $overlay.click(function () {
        $popupConfirm.removeClass("open");
        $overlay.off("click");
      });
    });
  });
});

$(document).ready(function () {
  initAnchorScrolling();
});
